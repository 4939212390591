.notbody {
    height: 100%;
    overflow: hidden;
    height: calc(100vh);
    padding: 4em;
    color: rgba(255, 255, 255, .75);
    background-color: rgb(25, 25, 25);
}

.notfound {
    display: flex;
    position: relative;
    top: 50%;
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255, 255, 255, .75);
    font-size: 180%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);
}
.notfound {
    animation: typewriter 4s steps(44) 1s 1 normal both,
        blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
    from {
        width: 0;
    }

    to {
        width: 24em;
    }
}

@keyframes blinkTextCursor {
    from {
        border-right-color: rgba(255, 255, 255, .75);
    }

    to {
        border-right-color: transparent;
    }
}