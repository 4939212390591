$primary-color: #ffd700;

// @import 'animate.css';

@font-face {
    font-family: 'American Captain';
    src: url(./assets//AmericanCaptain-MdEY.otf) format(otf);
}

body{
    overflow-x: hidden;
}
