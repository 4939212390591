:root{
    --primary-col: rgb(172, 73, 11);
    --secondary-col: #EC9F05;
}
.btn {
    position: relative;
    font-family: inherit;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.05em;
    border-radius: 0.8em;
    border: none;
    background: linear-gradient(to right,#EC9F05, #FF4E00);
    color: ghostwhite;
    overflow: hidden;
    cursor:pointer;
}

.btn svg {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
}

.btn span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
    display: inline-flex;
    align-items: center;
    padding: 0.8em 1.2em 0.8em 1.05em;
}

.btn::before,
.btn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.btn::before {
    content: "";
    background: rgb(99, 57, 19);
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.btn:hover::before {
    transform: translate3d(100%, 0, 0);
}

.btn:active {
    transform: scale(0.95);
}