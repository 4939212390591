
.home_container{
    width:100vw;
    height:100vh;
    display:flex;
    justify-content:center;
    align-items: center;
    gap:10rem;
}
.pic img{
    width:22rem;.custom-cursor {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 9999;
}

    height:26rem;
    object-fit: cover;
    border-radius:50%;
}

.content{
    padding:2rem;
    width:60rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:1rem;
}

.iconlist{
    display:flex;
    width:30rem;
    height:5rem;
    background-color: rgb(255, 255, 255);
}
.icon img{
    width:3rem;
    height:3rem;
    border-radius: 50%;
    background:rgba(218, 218, 218, 0.545);
    margin:1rem;
}

h3{
    font-size: 2rem;
}

h1{
    font-size: 5rem;
}
p{
    font-size: 1.5rem;
}