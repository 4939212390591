.navbar{
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding: 2rem 2rem 0rem 2rem;
}

.navbar_links ul{
    display:flex;
    justify-content:center;
    align-items: center;
}

.navbar_links ul li{
    list-style:none;
    margin:0 3rem;
    cursor: pointer;
    transition: .5s ease-in-out;
}
.navbar_links ul li a:hoverN{
    transform:scale(1.1);
}

.navbar_logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width:2rem;
    height:2rem;
    background-color:rgb(255, 159, 33);
    color:black;
    border-radius: 50%;
    cursor:pointer;
    transition: 0.3s;
    border: 1px solid grey;
}
.navbar_logo:hover{
    transform:scale(1.09);
}

.navlink{
    font-size: 30px;
    text-decoration:none;
    color: rgb(255, 159, 33);
    transition: all 0.3s ease;
}
.navlink:hover{
    color:red;
    transform: translateX(20px);    
}

